.mobile-phone-wrap {
  position: fixed;
  width: 327px !important;

  top: 130px !important;

  right: 80px;
  z-index: 100;
}

.mobile-phone-wrap ::-webkit-scrollbar {
  width: 4px;
}

#iframe ::-webkit-scrollbar {
  width: 4px;
}

.iframe-wrapper {
  position: relative;
  height: 100%;
  padding: 0 13px;
  overflow: auto;
  border-radius: 0px 0px 35px 35px;
  margin: -4px 0px 0 0;
}

iframe {
  position: absolute;
  height: 507px;
  top: 0;
  bottom: 0;
  border: none;
  border-radius: 0px 0px 35px 35px;
}

.order-menu {
  height: auto;
  width: 100%;
  bottom: 0;
  height: auto;
  overflow-y: auto;
  border-radius: 0px 0px 35px 35px;
}

/* Track */
.mobile-phone-wrap ::-webkit-scrollbar-track {
  background: rgb(189, 184, 184);
}

/* Handle */
.mobile-phone-wrap ::-webkit-scrollbar-thumb {
  background: gray;
}

@keyframes phoneAnimation {
  0% {
    right: -100px;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  100% {
    right: 0px;
    opacity: 1;
  }
}

.order-phone-wrap {
  opacity: 0;
  width: 336px;
  height: 513px;
  animation: phoneAnimation 1s ease-in-out;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 25px;
  zoom: 85%;
}

.order-phone {
  width: 336px;
  height: 628px;
  border-radius: 48px;
  background-size: contain;
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  border-radius: 48px;
  box-shadow: 1px 4px 8px 0px #aaaaaa;
}

.order-phone-header,
.menu-phone-header {
  width: 305px;
  height: 92px;
  margin-top: 16px;
  margin-left: 17px;
  margin-right: 15px;
  border-radius: 28px 28px 0 0;
}

.menu-phone-header {
  width: 313px;
  height: 92px;
  margin-top: 16px;
  margin-left: 12px;
}

.order-phone-text-wrap {
  width: 310px;
  height: 215px;
  margin-left: 16px;
  margin-right: 15px;
  background-color: white;
  position: relative;
}

.chat-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.user-text {
  position: relative;
  align-self: flex-end;
  padding: 5px 12px 9px;
  border-radius: 15px;
  height: auto;
  width: auto;
  max-width: 220px;
  background-color: #3b8073;
  word-break: break-all;
  animation: text-animation 0.3s ease-out forwards;
  text-transform: capitalize;
}

.user-text span {
  font-size: 13.5px;
  color: white;
}

.text-border {
  position: absolute;
  top: 100;
  width: 18px;
  bottom: 0;
  left: 100;
  right: 0;
}

.text-response {
  position: relative;
  height: auto;
  width: auto;
  max-width: 220px;
  animation: text-animation 0.3s ease-out forwards;
  border-radius: 15px;
  background-color: #dfdfdf;
  margin: 10px 0;
  padding: 9px 12px;
}

.text-response span {
  color: #413e3e;
  font-size: 13.5px;
  word-break: break-word;
  white-space: pre-line;
}

.response-border {
  position: absolute;
  top: 100;
  width: 18px;
  bottom: 0;
  left: 0;
  right: 100;
}

.response-text-url {
  text-decoration: underline;
  cursor: pointer;
  color: blue !important;
}

@keyframes text-animation {
  0% {
    transform: translateY(0px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(3px);
    opacity: 1;
  }
}

.order-phone-buttons-wrap {
  width: 301px;
  height: 242px;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #d7dadf;
  border-bottom-right-radius: 37px;
  border-bottom-left-radius: 36px;
  backdrop-filter: blur(30px);
  background-color: rgba(213, 215, 221, 0.93);
  padding-top: 7px;
  padding-left: 3px;
  padding-right: 3px;
}

.number-button {
  width: 87px;
  height: 34px;
  border-radius: 4.6px;
  box-shadow: 0 1px 0 0 #898a8d;
  background-color: #aaadb7;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.space-button {
  width: 182px;
  height: 34px;
  border-radius: 4.6px;
  box-shadow: 0 1px 0 0 #898a8d;
  background-color: #fcfcfe;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.send-button {
  width: 88px;
  height: 34px;
  border-radius: 4.6px;
  background-color: #0a7aff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
}

.bottom-buttons-wrap {
  display: flex;
  justify-content: space-between;
  height: 52px;
  padding-left: 11px;
  padding-right: 11px;
}

.bottuns-bar {
  width: 100px;
  border: 2px solid #000;
  border-radius: 100px;
  align-self: flex-end;
}

@keyframes cloudSlideAnimation {
  0% {
    top: -130px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

.cloud-wrap {
  opacity: 0;
  position: absolute;
  z-index: 101;
  top: -20px !important;
  right: -77px;
  animation: cloudSlideAnimation 1s ease-in-out;
  animation-delay: 3s;
  animation-fill-mode: forwards;
}

.cloud-image {
  width: 193px;
}

@keyframes blink {
  0% {
    height: 0px;
  }
  10% {
    height: 14px;
  }
  20% {
    height: 0px;
  }
  30% {
    height: 14px;
  }
  100% {
    height: 14px;
  }
}

.cloud-image-eye {
  position: absolute;
  z-index: 102;
  top: 73px;
  width: 14px;
  height: 14px;
}

.cloud-animation-left-eye,
.cloud-animation-right-eye {
  animation: blink 1s linear;
  animation-iteration-count: 2;
  animation-fill-mode: forwards;
}

.left-eye {
  right: 104px;
}

.right-eye {
  right: 73px;
}

@keyframes cloudMessageAnimation {
  0% {
    top: -130px;
    opacity: 0;
  }
  100% {
    top: -10px;
    opacity: 1;
  }
}

.cloud-message-wrap,
.menu-message-wrap {
  opacity: 0;
  width: 285px;
  background: transparent;
  color: white;
  top: -30px !important;
  position: absolute;
  right: 70px;
  z-index: 102;
  animation: cloudMessageAnimation 1s ease-in-out;
  animation-delay: 4s;
  animation-fill-mode: forwards;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.menu-message-wrap {
  position: relative;
  top: -20px !important;
  margin-top: -35px;
  width: 380px;
}

.cloud-message-wrap div,
.menu-message-wrap div {
  padding: 0 10px;
  font-size: 14px;
  margin: 0;
  background: #1a1346;
  box-shadow: 2px 2px 16px 0 rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

.close-icon {
  margin: 0;
  padding: 3px 0 10px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 1px;
  font-size: 9.1px;
  position: absolute;
  top: 43px;
  left: 57px;
  bottom: 0;
  height: 30px;
  color: white;
  z-index: 10;
}

.current-time {
  font-size: 11px;
  position: absolute;
  top: 18px;
  right: 74%;
  bottom: 0;
  height: 30px;
  color: white;
  z-index: 10;
}

.cloud-comment {
  position: absolute;
  width: 18px;
  bottom: 1px;
  right: -5px;
}

.simely {
  width: 27px;
  height: 27px;
}

.mic {
  width: 17px;
  height: 27px;
}

.grey-background {
  width: 27px;
}

.text-input-wrap {
  margin-left: 16px;
  margin-right: 15px;
  background-color: white;
  padding: 5px 10px;
  display: flex;
}

.text-input-wrap img {
  width: 37px;
  height: 26px;
  margin-right: 10px;
}

.phone-text-input {
  width: 100%;
  border-radius: 50px;
  border: 1px solid;
  height: 26px;
  padding-left: 10px;
}

.loading-text-response {
  width: 60px;
  height: 15px;
}

@media (min-width: 2000px) {
  .order-phone-wrap,
  .cloud-message-wrap,
  .cloud-wrap,
  .menu-message-wrap,
  .current-time,
  .close-icon {
    margin-right: 20vw;
  }
  .menu-message-wrap {
    position: absolute;
  }
  .close-icon {
    top: 51px;
    margin-left: -19.7vw;
  }
}

@media (min-width: 2200px) {
  .order-phone-wrap,
  .cloud-message-wrap,
  .cloud-wrap,
  .menu-message-wrap,
  .current-time,
  .close-icon {
    margin-right: 27vw;
  }
  .menu-message-wrap {
    position: absolute;
  }
  .close-icon {
    top: 51px;
    margin-left: -26.5vw;
  }
}

@media (min-width: 2800px) {
  .order-phone-wrap,
  .cloud-message-wrap,
  .cloud-wrap,
  .menu-message-wrap,
  .current-time,
  .close-icon {
    margin-right: 34vw;
  }
  .menu-message-wrap {
    position: absolute;
  }
  .close-icon {
    top: 51px;
    margin-left: -33.7vw;
  }
}
