.footer-wrap {
  height: 48px;
  margin: 96px 0 0;
  object-fit: contain;
  background-color: #108172;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
}
