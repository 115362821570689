.content {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: start;
  background: #fbfafa;
  display: flex;
  flex-direction: column;
  position: relative;
}

@keyframes pizzaTextAnimation {
  0% {
    margin-left: -600px;
    opacity: 0;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}

.title-text-wrap {
  opacity: 0;
  padding: 36px 64px;
  animation: pizzaTextAnimation 1s ease-in-out;
  animation-delay: 4s;
  animation-fill-mode: forwards;
  transform-origin: bottom left;
}

@media screen and (max-width: 1075px) {
  .title-text-wrap {
    width: 50%;
    padding: 36px 50px;
  }
}

.hunger-rush-text {
  font-size: 18px;
  font-family: FatFrank-Free;
  letter-spacing: 1px;
  color: #0e8476;
  margin: 0;
}

.srsly-smart-text {
  font-size: 45px;
  font-family: FatFrank-Free;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 1px;
  color: #1a1346;
  margin: 0;
}

.sms-description-text {
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #4b4b4b;
  width: 670px;
  margin-bottom: 58px;
  font-family: "FFNort-Regular";
}
@media screen and (max-width: 1145px) {
  .sms-description-text {
    width: 600px;
  }
}

.pizza-images-wrap {
  position: relative;
  width: 900px;
  height: 500px;
  padding: 36px 64px;
}

.pizza-images-wrap img {
  position: absolute;
  border-radius: 50%;
}

@keyframes rotateAndScale {
  0% {
    transform: scale(2) rotate(-200deg);
    opacity: 0;
  }
  100% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
}

@keyframes scaleImage {
  25% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes transitionFromLeft {
  0% {
    top: 380px;
    left: -130px;
    opacity: 0.3;
  }
  100% {
    top: 191px;
    left: 131px;
    opacity: 1;
  }
}

@keyframes scaleBigBlue {
  0% {
    transform: scale(2.3);
    opacity: 0.3;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeInBlue {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.pizza-img {
  width: 350px;
  z-index: 10;
  top: 61px;
  left: 220px;
  animation: rotateAndScale 1s ease-in-out;
}

.big-blue-img {
  width: 355px;
  z-index: 5;
  box-shadow: -2px 4px 24px 0 rgb(29 130 150 / 10%);
  top: -33px;
  left: 329px;
  animation: scaleBigBlue 1s ease-in-out;
}

.left-blue-img {
  width: 270px;
  z-index: 9;
  box-shadow: -2px 4px 24px 0 rgb(29 130 150 / 10%);
  top: 191px;
  left: 131px;
  animation: transitionFromLeft 1s ease-in;
}

.small-blue-img {
  width: 155px;
  z-index: 7;
  box-shadow: -2px 4px 24px 0 rgb(29 130 150 / 10%);
  top: 200px;
  left: 489px;
  animation: fadeInBlue 1s ease-in-out;
}

.big-white-img {
  width: 468px;
  z-index: 6;
  box-shadow: -2px 4px 24px 0 rgb(26 19 70 / 5%);
  top: -2px;
  left: 165px;
  animation: scaleImage 1s ease-in-out;
}

.small-white-img {
  width: 365px;
  z-index: 8;
  top: 52px;
  left: 215px;
  box-shadow: -2px 4px 24px 0 rgb(26 19 70 / 5%);
  border-radius: 50%;
  animation: scaleImage 1s ease-in-out;
}

.iphone-wrap {
  position: fixed;
  width: 423px;
  height: 861px;
  padding: 25px 24px 24px;
  object-fit: contain;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  margin-top: 130px;
  margin-right: 100px;
  top: 0;
  right: 0;
}

@keyframes dotsScaleImage {
  0% {
    transform: scale(3);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.pizza-images-wrap img.top-dots {
  opacity: 0;
  width: 300px;
  border-radius: 0;
  z-index: 11;
  top: -81px;
  animation: dotsScaleImage 1s ease-in-out;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  transform-origin: bottom left;
}

@keyframes fastEasyAnimation {
  0% {
    top: -180px;
    opacity: 0;
  }
  100% {
    top: 18px;
    opacity: 1;
  }
}

.pizza-images-wrap img.fast-easy {
  opacity: 0;
  width: 250px;
  border-radius: 0;
  z-index: 12;
  top: 18px;
  left: 100px;
  animation: fastEasyAnimation 1s ease-in-out;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  transform-origin: bottom left;
}

@keyframes predictiveAnimation {
  0% {
    top: -58px;
    opacity: 0;
  }
  100% {
    top: 100px;
    opacity: 1;
  }
}

.pizza-images-wrap img.predictive-analysis {
  opacity: 0;
  width: 250px;
  border-radius: 0;
  z-index: 12;
  top: 100px;
  left: 100px;
  animation: predictiveAnimation 1s ease-in-out;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  transform-origin: bottom left;
}

@keyframes smsMarketingAnmiation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pizza-images-wrap img.sms-marketing {
  opacity: 0;
  width: 222px;
  border-radius: 0;
  z-index: 12;
  top: 381px;
  left: 519px;
  animation: smsMarketingAnmiation 1s ease-in-out;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

@keyframes bottomDotsScaleImage {
  0% {
    transform: scale(3);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.pizza-images-wrap img.bottom-dots {
  opacity: 0;
  width: 222px;
  border-radius: 0;
  z-index: 11;
  top: 352px;
  left: 509px;
  animation: bottomDotsScaleImage 1s ease-in-out;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  transform-origin: top left;
}

.demo-wrap {
  margin-top: 180px;
  padding: 12px 0px 11px 64px;
  box-shadow: 10px 0 30px 0 rgba(220, 217, 216, 0.5);
  background-color: #fff;
  position: relative;
}

.demo-wrap.no-margin {
  margin: 0;
}

.demo-device {
  margin: 20px 381px 8px 0;
  font-size: 24px;
  letter-spacing: 0.67px;
  color: #4b4b4b;
  font-family: "FatFrank-Free";
}

.sample-easy {
  margin: 8px 319px 24px 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.56;
  color: #4b4b4b;
  font-family: "FFNort-Regular";
}

.access-demo-button {
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  margin: 24px 728px 20px 16px;
  border-radius: 50px;
  border: none;
  background-color: #128476;
  color: white;
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.demo-iphone-img {
  position: absolute;
  width: 312px;
  height: 237px;
  top: -32px;
  left: 600px;
}

@media screen and (max-width: 1250px) {
  .demo-iphone-img {
    display: none !important;
  }
}

.ordering-simplified-wrap {
  padding-left: 0;
  padding-top: 119px;
  position: relative;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.digital-ordering-img {
  width: 300px;
  position: absolute;
  top: 255px;
  left: 80%;
}

@media (min-width: 1500px) {
  .digital-ordering-img {
    left: 80%;
  }
}

.ordering-title {
  margin: 0 auto 32px;
  font-size: 40px;
  letter-spacing: 0.4px;
  color: #1a1346;
  font-family: FatFrank-Free;
  text-align: center;
}

.ordering-description {
  margin: 32px 1px 24px 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.56;
  color: #5f6369;
  width: 540px;
  font-family: "FFNort-Regular";
  text-align: center;
}

.check-mark-wraper {
  display: flex;
  align-items: start;
  margin-top: 24px;
}

.check-mark-text {
  width: 400px;
  margin-top: 0;
  margin-left: 30px;
  color: #5f6369;
  font-family: "FFNort-Regular";
}

.approachable-ai-wrap {
  margin-top: 86px;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.approachable-ai-title {
  margin: 0;
  font-size: 40px;
  letter-spacing: 0.4px;
  text-align: center;
  color: #1a1346;
  font-family: "FatFrank-Free";
}

.approachable-ai-description {
  width: 640px;
  font-size: 18px;
  line-height: 1.56;
  text-align: center;
  color: #5f6369;
  margin-top: 50px;
  font-family: "FFNort-Regular";
}

@media screen and (max-width: 1150px) {
  .approachable-ai-description {
    width: 500px;
  }
}

@media screen and (max-width: 1250px) {
  .approachable-items-wrap {
    flex-wrap: wrap;
    padding: 0% 5%;
  }
}

@media screen and (max-width: 1170px) {
  .approachable-ai-wrap {
    width: 60%;
  }

  .approachable-ai-title {
    padding: 0 5%;
  }
}

.approachable-items-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.approachable-items-wrap img {
  width: 80px;
}

.approachable-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 1250px) {
  .approachable-item-top {
    margin-top: 5%;
  }
}

.approachable-item-title {
  margin: 15px 0 5px 0;
  font-size: 18px;
  text-align: center;
  color: #282828;
  font-weight: 500;
  font-family: "FatFrank-Free";
}

.approachable-item-description {
  width: 266px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  color: #5f6369;
  margin: 0;
  font-family: "FFNort-Regular";
}

@media screen and (max-height: 790px) {
  .pizza-images-wrap img.top-dots {
    width: 230px;
  }

  @keyframes fastEasyAnimation {
    0% {
      top: -180px;
      opacity: 0;
    }
    100% {
      top: -6px;
      opacity: 1;
    }
  }

  .pizza-images-wrap img.fast-easy {
    width: 218px;
    top: -6px;
    left: 89px;
    animation: fastEasyAnimation 1s ease-in-out;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    transform-origin: bottom left;
  }

  @keyframes predictiveAnimation {
    0% {
      top: -58px;
      opacity: 0;
    }
    100% {
      top: 63px;
      opacity: 1;
    }
  }

  .pizza-images-wrap img.predictive-analysis {
    width: 218px;
    top: 63px;
    left: 89px;
    animation: predictiveAnimation 1s ease-in-out;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    transform-origin: bottom left;
  }

  .pizza-img {
    width: 300px;
    top: 24px;
    left: 189px;
  }

  .small-white-img {
    width: 312px;
    top: 16px;
    left: 184px;
  }

  .big-white-img {
    width: 422px;
    top: -39px;
    left: 132px;
  }

  .small-blue-img {
    width: 138px;
    top: 146px;
    left: 425px;
  }

  .big-blue-img {
    width: 315px;
    top: -57px;
    left: 280px;
  }

  @keyframes transitionFromLeft {
    0% {
      top: 380px;
      left: -130px;
      opacity: 0.3;
    }
    100% {
      top: 122px;
      left: 99px;
      opacity: 1;
    }
  }

  .left-blue-img {
    width: 245px;
    top: 122px;
    left: 99px;
    animation: transitionFromLeft 1s ease-in;
  }

  .pizza-images-wrap img.bottom-dots {
    width: 195px;
    top: 300px;
    left: 436px;
  }

  .pizza-images-wrap img.sms-marketing {
    width: 183px;
    top: 323px;
    left: 453px;
  }
}

.toast-body {
  color: white;
  font-weight: bold;
  text-align: center;
}

.Toastify__toast-container {
  width: 820px;
}
