.buttons-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}

.keyboard-button {
  width: 20px;
  height: 26px;
  border-radius: 4.6px;
  box-shadow: 0 1px 0 0 #898a8d;
  background-color: #fcfcfe;
  font-size: 20px;
  letter-spacing: -0.55px;
  text-align: center;
  color: #000;
  padding: 2px 3px;
  cursor: pointer;
}

.button-second-row {
  margin-left: 18px;
  margin-right: 18px;
}

.grey-background {
  background: #aaadb7;
  width: 27px;
}

.arrow-up {
  width: 64px;
  margin-top: -19px;
  margin-left: -23px;
}

.backspace-button {
  width: 19px;
  margin-top: 4px;
  margin-left: -2px;
}

.keyvoard-symbol-wrap {
  display: flex;
}

.keyvoard-symbol-wrap .keyboard-button {
  margin-right: 7px;
}

.symbol-button-wrap.keyboard-button {
  width: 35px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
